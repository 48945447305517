$white: #fff;
$black: #000;

$purple-normal: #4f8af2;

$gray-lighter-2: #edeef2;
$gray-lighter-3: #e5e7eb;

$purple-lighter-3: #f3f2f7;
$purple-darker: #363984;

$gray-darker-2: #5a5f6f;
$gray-darker-3: #3f3f44;

$gray-medium-1: #d7d8e0;
$gray-medium-2: #dddde1;
$gray-medium-6: #a2a3a9;

$text-lighter: #c1c1c9;

$light-steel-blue-normal: #cbd0e3;