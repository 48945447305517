@import './variables.scss';
@import '../../node_modules/wizard-ui/src/style/variables.scss';
@import './detail.scss';
@import './icon.scss';

html,body {
  font-family: "Microsoft YaHei", "微软雅黑", sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.breadcrumb>li+li:before {
  content: '/';
}

/* btn样式覆盖 */
/* 去除focus状态的蓝色边框 */
a:focus,
.btn:focus,
button:focus {
  outline: none !important;
}

#root {
  height: 100%;
}


.center {
  text-align: center;
}

.icon {
  font-size: 18px;
  vertical-align: middle;
}

.na {
  color: #A2A3A9;
}
.maxWidth300 {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.link {
  color: $purple-normal;
  cursor: pointer;
}

.vam {
  vertical-align: middle;
  display: inline-block;
  max-width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
 .modal-content {
   border-radius: 2px;
 }
 .modal-header {
   padding-left: 24px;
 }

 .thumbnail {
  width: 100%;
  max-width: 325px;
  min-height: 175px;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3F3F44;
  padding: 0;
  border: 0;
  border-radius: 0;
  &-item {
    width: 100%;
    text-align: center;
  }
}

.NotificationList__control {
  .icon {
    width: auto;
    height: auto;
  }
}

.Dashboard {
  background-color: #F5F5F5;
  height: 100%;
  position: relative;
  &__up {
    background-color: #F5F5F5;
    position: relative;
    height: calc(100% - 50px) !important;
  }
}

.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
  outline: none;
}

.error {
  color: $brand-danger;
  font-size: 12px;
  line-height: 20px;
}

.EmptyPage {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  &__icon {
    width: 100%;
    text-align: center;
    margin-bottom: 8px;
    & > .icon {
      width: 48px !important;
      height: 48px !important;
    }
  }
}
.tooltip-inner {
  text-align: left;
  max-width: 300px;
  ul {
    padding-left: 16px;
  }
}

.Progress {
  .progress {
    height: 6px;
    width: 200px;
    margin: 0 auto 12px;
  }
  .progress-bar-success {
    background-color: #4f8af2;
  }
  &__error {
    background-color: #F5F5F5;
    padding: 8px 16px;
    max-height: 300px;
    overflow: scroll;
  }
  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 16px;
    .icon {
      margin-right: 8px;
    }
  }
  &__count {
    color: #646369;
    font-size: 12px;
    text-align: center;
  }
  &-rename,
  &-isPaste {
    color: #448AFF;
  }
  &-success {
    color: #30B65F;
  }
  &-fail {
    color: #F24542;
  }
}

.breadcrumb {
  li {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}