@import './variables.scss';
.DetailPage {
    margin: 8px;
    height: calc(100% - 66px);
    .breadcrumb .icon {
      margin-right: 10px;
    }
    .breadcrumb {
      background: none;
      margin-bottom: 4px;
    }
    &__content {
      padding-left: 8px;
      height: calc(100% - 42px) !important;
      .icon {
        display: inline-block;
        width: 18px;
        height: 18px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0 0;
      }
    }
    &__left {
      background-color: $white;
      box-shadow: rgba(0, 0, 0, 0.2) 0 0 1px 0;
      padding-top: 32px;
      height: 100%;
      overflow-y: auto;
      padding-bottom: 16px;
      padding-right: 0;
    }
    &__right {
      height: 100%;
      &-content {
        overflow: auto;
        height: 100%;
        background-color: $white;
        padding: 16px !important;
      }
      h4.title {
        margin-bottom: 8px;
        display: flex;
      }
      h5 {
        color: #3F3F44;
      }
      tr.table-placeholder > td {
        border: 0;
      }
      .EmptyPage {
        min-height: calc(100vh - 330px) !important;
      }
      &__content {
        overflow-y: auto;
        height: calc(100% - 42px) !important;
      }
      p {
        color: #A2A3A9;
      }
    }
    .Form {
      position: relative;
      height: 100%;
      .input-group {
        width: 150px;
      }
    }
    .Form__content {
      height: calc(100% - 50px);
      overflow-y: auto;
      overflow-x: hidden;
    }
    .Form__footer {
      margin: 0;
      background-color: #fff;
      button {
        margin-left: 16px;
      }
      &--outer {
        position: absolute;
        width: 100%;
        bottom: -16px;
      }
    }
  }