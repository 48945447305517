.icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 0;
  margin-right: 4px;
  &.icon-image {
    background-image: url('../svgs/picture.svg');
  }
  &.icon-txt {
    background-image: url('../svgs/txt.svg');
  }
  &.icon-doc {
    background-image: url('../svgs/word.svg');
  }
  &.icon-xls {
    background-image: url('../svgs/xls.svg');
  }
  &.icon-ppt {
    background-image: url('../svgs/ppt.svg');
  }
  &.icon-ps {
    background-image: url('../svgs/ps.svg');
  }
  &.icon-zip {
    background-image: url('../svgs/zip.svg');
  }
  &.icon-movie {
    background-image: url('../svgs/movie.svg');
  }
  &.icon-music {
    background-image: url('../svgs/music.svg');
  }
  &.icon-pdf {
    background-image: url('../svgs/pdf.svg');
  }
  &.icon-folder {
    background-image: url('../svgs/folder.svg');
  }
  &.icon-default {
    background-image: url('../svgs/default.svg');
  }
  &.icon-set {
    background-image: url('../svgs/else/set.svg');
  }
  &.icon-download {
    background-image: url('../svgs/else/download.svg');
  }
  &.icon-remove {
    background-image: url('../svgs/else/delete.svg');
  }
  &.icon-empty {
    background-image: url('../svgs/else/empty.svg');
  }
  &.icon-empty-folder {
    background-image: url('../svgs/else/empty-folder.svg');
  }
  &.icon-pasting {
    background-image: url('../svgs/else/pasting.svg');
  }
  &.icon-permission {
    background-image: url('../svgs/else/permission.svg');
  }
}